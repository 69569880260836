import { render, staticRenderFns } from "./DagInvestmentFormSubmitsTable.vue?vue&type=template&id=75c931ea&scoped=true"
import script from "./DagInvestmentFormSubmitsTable.vue?vue&type=script&lang=js"
export * from "./DagInvestmentFormSubmitsTable.vue?vue&type=script&lang=js"
import style0 from "./DagInvestmentFormSubmitsTable.vue?vue&type=style&index=0&id=75c931ea&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75c931ea",
  null
  
)

export default component.exports