<template>
    <div>
        <div class="login-content__block__title">{{ translate('two-factor-auth.login') }}</div>
        <div class="login-content__block__description">{{ translate('two-factor-auth.login_with_existing_account') }}</div>
        <form method="POST" :action="loginRoute" @submit.prevent="login">
            <input type="hidden" name="headerInput" value="1">

            <div class="dg-input-wrapper dg-text-input-wrapper has-icon" :class="{'has-error': error}">
                <input v-model="form.username" type="text" name="username" :placeholder="translate('two-factor-auth.user')" :aria-label="translate('two-factor-auth.user')" autocomplete="username">
                <i class="icon-person"></i>
            </div>

            <div class="dg-input-wrapper dg-text-input-wrapper has-icon" :class="{'has-error': error}">
                <input v-model="form.password" type="password" name="password" :placeholder="translate('two-factor-auth.password')" :aria-label="translate('two-factor-auth.password')" autocomplete="current-password">
                <i class="icon-lock-alt"></i>
            </div>

            <div v-if="error" class="dg-input-error">{{ translate(errorMessage) }}</div>

            <div v-if="!isApp" class="dg-input-wrapper dg-checkbox-input-wrapper">
                <input v-model="form.remember" type="checkbox" name="remember" id="remember">
                <label for="remember">{{ translate('two-factor-auth.stay_logged_in') }}</label>
            </div>

            <button type="submit" class="dg-button color-primary size-extended" :disabled="loading">{{ translate('two-factor-auth.login') }}</button>

            <div class="mt-3" v-if="ichAppEnabled">
                {{ translate('ichapp.or') }}
            </div>
            <div class="mt-3" v-if="ichAppEnabled">
                <a :href="ichAppLoginRoute" class="ich-button">{{ translate('ichapp.login') }}</a>
            </div>

        </form>
        <div class="login-content__block__forgot-password">
            <a :href="resetPasswordRoute">{{ translate('two-factor-auth.forgot_and_change_password') }}</a>
        </div>

    </div>
</template>

<script>
import xhr from '../../core/xhr';

export default {
    props: {
        isApp: {
            type: Boolean,
            default: false
        },
        headerInput: {
            type: Boolean,
            default: false
        },
        loginRoute: {
            type: String,
            required: true
        },
        resetPasswordRoute: {
            type: String,
            required: true
        },
        ichAppLoginRoute: {
            type: String,
            required: true
        },
        ichAppEnabled: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            error: false,
            loading: false,
            errorMessage: null,
            form: new xhr({
                'username': '',
                'password': '',
                'remember': false,
                'headerInput': this.headerInput
            })
        }
    },
    methods: {
        async login() {
            this.error = false;
            this.loading = true;
            this.errorMessage = null;

            let response = null

            try {
                response = await this.form.post(this.loginRoute);
            } catch (error) {
                this.error = true;
                this.loading = false;
                this.errorMessage = this.form.errors.get('username');
            }

            console.log('response', typeof response, response);


            if (response) {
                this.$emit('loginSuccess', response);
            }
        }
    },
}
</script>
