<template>
    <div class="row">
        <div class="col-12 col-md-5 col-lg-4 offset-xl-1 order-md-last d-flex flex-column">
            <div class="investment-overlay__block__hints">
                <!-- FIXME: The minimum investment info hint should not be shown for now -->
                <!-- <div class="investment-overlay__block__hint" v-if="displayedHint === 1 && !isPlatinumProduct" v-html="translate('info_minimum_investment', [{ key: 'MINIMUM_INVESTMENT', value: minimumInvestment }, { key: 'INVESTMENT_STEPS', value: investmentStep }])"></div> -->
                <div class="investment-overlay__block__hint investment-overlay__block__hint--important" v-if="displayedHint === 2 && !userQualifiesForLargerInvestments" v-html="translate('info_maximum_investment_regular', [{ key: 'MAXIMUM_INVESTMENT', value: new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(userMaximumInvestment)}, { key: 'REDIRECT_LINK', value: getUrl() }])"></div>
                <div class="investment-overlay__block__hint" v-else-if="displayedHint === 2 && userQualifiesForLargerInvestments && !displayedSourceOfFunds" v-html="translate('info_maximum_investment_large', [{ key: 'MAXIMUM_INVESTMENT', value: new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(userMaximumInvestment) }])"></div>
                <div class="investment-overlay__block__hint investment-overlay__block__hint--important" v-if="displayedSourceOfFunds && displayedSourceOfFunds == 10000" v-html="translate('info_source_of_funds_10000')"></div>
                <div class="investment-overlay__block__hint investment-overlay__block__hint--important" v-if="displayedSourceOfFunds && displayedSourceOfFunds == 50000" v-html="translate('info_source_of_funds_50000')"></div>
            </div>
            <div class="investment-overlay__block__hint investment-overlay__block__hint--important" v-if="voucherCodeIsValid && !userHasProvidedIban" v-html="translate('user_has_not_given_iban')"></div>
            <div class="investment-overlay__block__voucher-code-message d-none d-md-block" v-if="isFirstInvestment && !(showVoucherCodeMessage || voucherCodeError)" v-html="translate('info_first_investment')"></div>
            <div class="investment-overlay__block__voucher-code-message investment-overlay__block__voucher-code-message--success d-none d-md-block" v-if="showVoucherCodeMessage" v-html="translate('info_action_code', [{ key: 'MINIMUM_INVESTMENT', value: new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(this.userMinimumInvestment) }])"></div>
            <div class="investment-overlay__block__voucher-code-message investment-overlay__block__voucher-code-message--error d-none d-md-block" v-if="voucherCodeError">
                {{ voucherCodeError }}
            </div>
        </div>
        <div class="col-12 col-md-5 col-lg-4 col-xl-3 offset-md-1 offset-lg-2">
            <div class="investment-overlay__block__investment-value">
                <div class="dg-input-wrapper dg-text-input-wrapper" :class="{ 'has-error': investmentError, 'has-icon': !(lockValue || investmentError) }">
                    <input type="text" name="investment" :placeholder="inputPlaceholder" v-model="inputValue" @focus="onFocus" @blur="onBlur" @input="onInput" :readonly="lockValue">
                    <i class="icon-plus" @click="increase" v-if="!(lockValue || investmentError)"></i>
                    <i class="icon-minus" @click="decrease" v-if="!(lockValue || investmentError)"></i>
                </div>
                <div class="dg-input-error" v-if="investmentError" v-html="investmentError"></div>
                <div class="investment-overlay__block__investment-value__change" v-if="lockValue">
                    <button type="button" class="dg-button color-primary size-reduced" @click="enableValue">{{ translate('change') }}</button>
                </div>
            </div>
            <div class="investment-overlay__block__payment-breakdown">
                <table>
                    <tr>
                        <th>{{ translate('investment') }}:</th>
                        <td>EUR {{ selectedValue | money }}</td>
                    </tr>
                    <tr v-if="this.serviceFeePercentage > 0">
                        <th>
                            <span>
                                {{ translate('service_fee_percent', [{ key: 'SERVICE_FEE_PERCENTAGE', value: $options.filters.percentage(serviceFeePercentage) }]) }}:

                                <div class="dg-info-box">
                                    <div class="dg-info-box-button">
                                        <button type="button">
                                            <span class="sr-only">{{ translate('show_more_information') }}</span>
                                        </button>
                                    </div>
                                    <div class="dg-info-box-content">
                                        <div class="dg-info-box-close">
                                            <button type="button">
                                                <span class="sr-only">{{ translate('close') }}</span>
                                            </button>
                                        </div>
                                        <div class="dg-info-box-title">
                                            {{ translate('service_fee') }}:
                                        </div>
                                        <div class="dg-info-box-text">
                                            {{ translate('service_fee_info_box', [{ key: 'SERVICE_FEE_PERCENTAGE', value: $options.filters.percentage(serviceFeePercentage) }]) }}
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </th>
                        <td>EUR {{ serviceFee | money }}</td>
                    </tr>
                    <tr>
                        <th>{{ translate('payable') }}:</th>
                        <td>EUR {{ selectedValue - zeroBondDiscount + serviceFee | money }}</td>
                    </tr>
                </table>
            </div>
            <div class="investment-overlay__block__voucher-code">
                <div class="dg-input-wrapper dg-text-input-wrapper" :class="{ 'has-error': voucherCodeError, 'has-icon': voucherCodeIsValid }">
                    <input type="text" name="actionCode" :placeholder="translate('coupon_or_action_code')" v-model="voucherCode"  @input="resetVoucherCodeStatus" @blur="checkVoucherCode" :readonly="lockVoucherCode">
                    <i class="icon-confirm" v-if="voucherCodeIsValid"></i>
                </div>
                <div class="investment-overlay__block__voucher-code__change" v-if="lockVoucherCode">
                    <button type="button" class="dg-button color-primary size-reduced" @click="enableVoucherCode">{{ translate('change') }}</button>
                </div>
            </div>
            <div class="investment-overlay__block__voucher-code-message investment-overlay__block__voucher-code-message--mobile d-md-none" v-if="isFirstInvestment && !(showVoucherCodeMessage || voucherCodeError)" v-html="translate('info_first_investment')"></div>
            <div class="investment-overlay__block__voucher-code-message investment-overlay__block__voucher-code-message--mobile investment-overlay__block__voucher-code-message--success d-md-none" v-if="showVoucherCodeMessage" v-html="translate('info_action_code', [{ key: 'MINIMUM_INVESTMENT', value: new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(this.userMinimumInvestment) }])"></div>
            <div class="investment-overlay__block__voucher-code-message investment-overlay__block__voucher-code-message--mobile investment-overlay__block__voucher-code-message--error d-md-none" v-if="voucherCodeError">
                {{ voucherCodeError }}
            </div>
        </div>
    </div>
</template>

<script>
import Xhr from '../core/xhr'

export default {
    props: {
        productId: {
            type: Number
        },
        startInvestment: {
            type: Number
        },
        minimumInvestment: {
            type: Number
        },
        investmentStep: {
            type: Number,
            default: 10
        },
        maximumRegularInvestment: {
            type: Number,
            default: 1000
        },
        userInvestedAlready: {
            type: Number
        },
        userMinimumInvestment: {
            type: Number
        },
        userMaximumInvestment: {
            type: Number
        },
        userMaximumSafeInvestment: {
            type: Number
        },
        userQualifiesForLargerInvestments: {
            type: Boolean,
            default: false
        },
        userIsInexperienced: {
            type: Boolean,
            default: false
        },
        projectDuration: {
            type: Number,
            default: 12
        },
        serviceFeePercentage: {
            type: Number
        },
        isFirstInvestment: {
            type: Boolean,
            default: false
        },
        userUnderstandsRisk: {
            type: Boolean,
            default: false
        },
        isPlatinumProduct: {
            type: Boolean,
            default: false
        },
        oldInvestmentError: {
            type: String,
            default: null
        },
        oldVoucherCodeError: {
            type: String,
            default: null
        },
        oldVoucherCode: {
            type: String,
            default: null
        },
        lockValue: {
            type: Boolean,
            default: false
        },
        lockVoucherCode: {
            type: Boolean,
            default: false
        },
        transactionId: {
            type: String,
            default: null
        },
        contractType: {
            type: String,
            default: null
        },
        interestRate: {
            type: Number,
            default: 0
        },
        discountPercentage: {
            type: Number,
            default: 0
        },
        userSourceOfFundsCheck: {
            type: Boolean,
            default: false
        },
        userSourceOfFundsDoc: {
            type: Boolean,
            default: false
        },
        userHasProvidedIban: {
            type: Boolean,
            default: false
        },
        userHasProvidedIdentification: {
            type: Boolean,
            default: false
        },
        userHasProvidedExperience: {
            type: Boolean,
            default: false
        },
        userHasProvidedSimulation: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            selectedValue: null,
            inputValue: null,
            investmentError: null,
            voucherCode: null,
            voucherCodeError: null,
            voucherCodeIsValid: false,
            showVoucherCodeMessage: false,
            oldUserMinimumInvestmentValue: 0,
            freeServiceFee: 0
        }
    },

    computed: {
        inputPlaceholder () {
            return 'EUR ' + Intl.NumberFormat(
                'de-DE',
                { minimumFractionDigits: 0, maximumFractionDigits: 0 }
            ).format(this.userMinimumInvestment)
        },

        serviceFee () {
            if(this.freeServiceFee == 1) {
                return 0
            } else {
                const investmentValue = this.selectedValue
                    ? parseInt(this.selectedValue)
                    : 0

                return investmentValue * (this.serviceFeePercentage / 100) / 12 * this.projectDuration
            }
        },

        zeroBondDiscount () {

            if (this.contractType !== 'Zero Bond') {
                return 0;
            }

            const discountPercentage = parseFloat(this.discountPercentage);
            const investmentValue = this.selectedValue
                ? parseInt(this.selectedValue)
                : 0


            return investmentValue * discountPercentage / 100
        },

        displayedHint () {
            if (this.selectedValue >= this.userMaximumInvestment) {
                return 2
            }

            if (this.minimumInvestment < 250) {
                return 1
            }

            return 0
        },

        displayedSourceOfFunds() {
            if(this.selectedValue >= 10000 && this.userSourceOfFundsCheck !== true) {
                return 10000;
            }

            if(this.selectedValue >= 50000 && this.userSourceOfFundsDoc !== true) {
                return 50000;
            }

            return false;
        },

        showRiskWarning () {
            // If the user doesn't qualify for larger investments
            // (> {{ maximumRegularInvestment }} Euro)
            // we always show the risk warning
            if (!this.userQualifiesForLargerInvestments) {
                return true
            }

            // If the user is inexperienced, we always show the risk warning
            if (this.userIsInexperienced) {
                return true
            }

            // Users who qualify for larger investments who invest more than
            // {{ maximumRegularInvestment }} Euro will get the warning
            // displayed if the value exceeds their maximum safe investment
            // value
            if (
                this.selectedValue > this.maximumRegularInvestment &&
                this.selectedValue > this.userMaximumSafeInvestment
            ) {
                return true
            }

            return false
        },

        allowedInvestmentWithoutRiskUnderstanding () {
            return Math.max(
                this.userMaximumSafeInvestment, this.maximumRegularInvestment
            )
        }
    },

    methods: {
        isDesktopResolution () {
            return window.matchMedia('(min-width: 992px)').matches
        },

        onFocus () {
            if (this.lockValue) {
                return
            }

            this.resetInvestmentStatus()

            this.inputValue = this.selectedValue
        },

        onBlur () {
            if (this.lockValue) {
                return
            }

            if (this.inputValue < this.userMinimumInvestment) {
                this.inputValue = this.userMinimumInvestment
            }

            if (this.inputValue > this.userMaximumInvestment) {
                this.inputValue = this.userMaximumInvestment
            }

            if (this.inputValue % this.investmentStep) {
                this.inputValue = Math.round(this.inputValue / this.investmentStep) * this.investmentStep
            }

            this.selectedValue = this.inputValue
            this.inputValue = 'EUR ' + Intl.NumberFormat(
                'de-DE',
                { minimumFractionDigits: 0, maximumFractionDigits: 0 }
            ).format(this.inputValue)

            this.toggleInvestmentProhibition()

            this.fireEvents()
        },

        onInput () {
            if (this.lockValue) {
                return
            }

            // FIXME: There was an issue where pasting a number with a `.` or
            // a `,` wasn't properly transformed by `Number()`; the issue
            // couldn't be replicated in testing, but manually filtering out
            // those characters should prevent it anyway.
            this.inputValue = this.inputValue
                .split('.')
                .join('')
                .split(',')
                .join('')
            this.inputValue = Number(this.inputValue)

            if (Number.isNaN(this.inputValue)) {
                this.inputValue = this.userMinimumInvestment
            }

            if (this.selectedValue > this.userMaximumInvestment) {
                this.inputValue = this.userMaximumInvestment
            }

            this.selectedValue = this.inputValue

            this.fireEvents()
        },

        increase () {
            if (this.lockValue) {
                return
            }

            if ((this.selectedValue + this.investmentStep) <= this.userMaximumInvestment) {
                this.selectedValue = this.selectedValue + this.investmentStep
            }

            this.inputValue = 'EUR ' + Intl.NumberFormat(
                'de-DE',
                { minimumFractionDigits: 0, maximumFractionDigits: 0 }
            ).format(this.selectedValue)

            this.toggleInvestmentProhibition()

            this.fireEvents()
        },

        decrease () {
            if (this.lockValue) {
                return
            }

            if ((this.selectedValue - this.investmentStep) >= this.userMinimumInvestment) {
                this.selectedValue = this.selectedValue - this.investmentStep
            }

            this.inputValue = 'EUR ' + Intl.NumberFormat(
                'de-DE',
                { minimumFractionDigits: 0, maximumFractionDigits: 0 }
            ).format(this.selectedValue)

            this.toggleInvestmentProhibition()

            this.fireEvents()
        },

        fireEvents () {
            window.App.dispatcher.fire(
                'investmentValueChange',
                { investmentValue: this.selectedValue, zeroBondDiscount: this.zeroBondDiscount }
            )

            window.App.dispatcher.fire(
                'serviceFeeChange',
                { serviceFee: this.freeServiceFee == 1 ? 0 : this.serviceFee }
            )

            window.App.dispatcher.fire(
                'riskWarningDisplayChange',
                { showRiskWarning: this.showRiskWarning }
            )
        },

        enableValue () {
            this.lockValue = false
        },

        resetInvestmentStatus () {
            this.investmentError = null
        },

        enableVoucherCode () {
            this.lockVoucherCode = false
        },

        resetVoucherCodeStatus () {
            this.voucherCodeError = null
            this.showVoucherCodeMessage = false
            this.voucherCodeIsValid = false
            this.freeServiceFee = 0
        },

        checkVoucherCode () {
            this.resetVoucherCodeStatus()

            if (this.voucherCode.trim() === '') {
                this.userMinimumInvestment = this.oldUserMinimumInvestmentValue

                if (this.userMinimumInvestment > this.selectedValue) {
                    this.selectedValue = this.userMinimumInvestment
                    this.inputValue = 'EUR ' + Intl.NumberFormat(
                        'de-DE',
                        { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                    ).format(this.selectedValue)
                }
                this.fireEvents()

                return
            }

            new Xhr({
                productId: this.productId,
                actionCode: this.voucherCode,
                investment: this.selectedValue,
                transactionid: this.transactionId
            }).post('/invest/checkActionCode').then(response => {
                this.voucherCodeError = null

                if (false) {// if voucher info element exists
                    // FIXME: Show voucher code info if available; this was the case in Spinner.vue.
                    // Is this still a thing?
                } else {
                    const newInvestmentFormSpecialFiles = document.querySelectorAll(
                        '.investment-overlay__block__document--special-file'
                    )
                    for (const file of newInvestmentFormSpecialFiles) {
                        file.classList.add(
                            'investment-overlay__block__document--hidden'
                        )
                    }
                }

                if (response.min_value !== null) {
                    this.userMinimumInvestment = Number(response.min_value)
                    this.showVoucherCodeMessage = true
                }

                this.freeServiceFee = response.free_sevice_fee

                this.voucherCodeIsValid = true
                this.fireEvents()
            }).catch (error => {
                this.voucherCodeError = error.errors.actionCode[0]

                const newInvestmentFormSpecialFiles = document.querySelectorAll(
                    '.investment-overlay__block__document--special-file'
                )
                for (const file of newInvestmentFormSpecialFiles) {
                    file.classList.add(
                        'investment-overlay__block__document--hidden'
                    )
                }

                this.userMinimumInvestment = this.oldUserMinimumInvestmentValue

                if (this.userMinimumInvestment > this.selectedValue) {
                    this.selectedValue = this.userMinimumInvestment
                    this.inputValue = 'EUR ' + Intl.NumberFormat(
                        'de-DE',
                        { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                    ).format(this.selectedValue)
                }
                this.fireEvents()
            })
        },

        toggleInvestmentProhibition () {
            const hiddenBlocksWhenProhibited = document.querySelectorAll(
                '.investment-overlay-hide-when-prohibited'
            )
            const shownBlocksWhenProhibited = document.querySelectorAll(
                '.investment-overlay-show-when-prohibited'
            )

            if (
                !this.userUnderstandsRisk &&
                this.allowedInvestmentWithoutRiskUnderstanding < this.selectedValue
            ) {
                for (const block of hiddenBlocksWhenProhibited) {
                    block.classList.add('d-none')
                }

                for (const block of shownBlocksWhenProhibited) {
                    block.classList.remove('d-none')
                }

                return
            }

            for (const block of hiddenBlocksWhenProhibited) {
                block.classList.remove('d-none')
            }

            for (const block of shownBlocksWhenProhibited) {
                block.classList.add('d-none')
            }
        },

        getUrl ()  {
            if(this.userHasProvidedIdentification === false) {
                return '/backend/profildaten/dokumente';
            }

            if(this.userHasProvidedExperience === false) {
                return '/backend/profildaten/experienceData';
            }

            if(this.userHasProvidedSimulation === false) {
                return '/backend/profildaten/lossBearingCapacity';
            }

            return '/backend/profildaten/persoenliche-daten';
        }
    },

    filters: {
        money (value) {
            return new Intl.NumberFormat(
                'de-DE',
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            ).format(value)
        },

        percentage (value) {
            // FIXME: this could be improved
            return String(value).replace('.', ',') + '%'
        }
    },

    created () {
        let startInvestment = Number(this.startInvestment) % Number(this.investmentStep) !== 0
            ? this.investmentStep * Math.ceil(Number(this.startInvestment) / Number(this.investmentStep))
            : this.startInvestment

        this.selectedValue = startInvestment > Number(this.userMaximumInvestment)
            ? this.userMaximumInvestment
            : startInvestment
        this.inputValue = 'EUR ' + Intl.NumberFormat(
            'de-DE',
            { minimumFractionDigits: 0, maximumFractionDigits: 0 }
        ).format(this.selectedValue)
        this.oldUserMinimumInvestmentValue = this.userMinimumInvestment

        if (this.oldInvestmentError) {
            this.investmentError = this.oldInvestmentError
        }

        if (this.oldVoucherCode) {
            this.voucherCode = this.oldVoucherCode

            if (this.oldVoucherCodeError) {
                this.voucherCodeError = this.oldVoucherCodeError
                return
            }

            this.checkVoucherCode()
        }

        setTimeout(() => {
            this.fireEvents()
            this.toggleInvestmentProhibition()
        }, 100)
    },
}
</script>
