<template>
    <div class="text-center" v-if="loading">
        LOADING...
    </div>
    <table class="table" v-else>
        <thead>
            <tr>
                <th>Name</th>
                <th>Emittent&nbsp;ID</th>
                <th>Emittent</th>
                <th>Emittent&nbsp;Email</th>
                <th>Email&nbsp;Beschwerde</th>
                <th>New&nbsp;email</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="project in projects" :key="project.id">
                <td>{{project.bezeichnung}}</td>
                <td>{{project.i_14}}</td>
                <td>{{project.v_6}}</td>
                <td>{{project.emittentEmail}}</td>
                <td :id="'field'+project.id">{{project.v_15}}</td>
                <td>
                    <input type="text" :id="'input'+project.id" placeholder="Enter New email" /><br>
                    <button class="btn btn-primary small" @click="submitInput(project.id)">Confirm</button>
                </td>
            </tr>
        </tbody>
    </table>
</template>
<script>
    import Xhr from '../../core/xhr';

    export default {
        created() {
            this.loading = true;
            new Xhr().get('/dagoadmin/email-change-contact-button/get-projects').then((response) => {
                this.projects = response;
                this.loading = false;
            })
        },

        methods: {
            submitInput(pid) {
                let email = document.getElementById('input'+pid).value;

                new Xhr({pid: pid, email:email}).post('/dagoadmin/email-change-contact-button/submit-input').then((response) => {
                    document.getElementById('field'+pid).innerHTML = response;
                });
            }
        },

        data() {
            return {
                projects: [],
                loading: true
            };
        }
    }
</script>