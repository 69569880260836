<template>
    <div class="row">
        <div class="col-12">
            <div class="bg-white">
                <div class="row" v-if="isOnDashboard || (investments.length > 0 && loading == 0)">
                    <div class="col-6"><div class="title">{{ translate('all_investments') }}</div></div>
                    <div class="col-6 text-right" v-if="!disableCsvDownload">
                        <button class="btn btn-secondary" @click="downloadCSV()">{{ translate('download_csv') }}</button>
                    </div>
                </div>
                <div class="title"  v-if="!isOnDashboard && investments.length == 0 && loading == 0">{{ translate('make_a_selection') }}</div>
                <div class="title text-center"  v-if="loading == 1">{{ translate('loading_please_wait') }}</div>
                <table class="d-none d-sm-table">
                    <thead>
                        <tr>
                            <td><a href="#" :class="['sort', this.projectSort == '' ? '' : this.projectSort == 'ASC' ? 'ASC' : 'DESC']" @click.prevent="onProjectClick">{{ translate('projects') }}</a></td>
                            <td><a href="#" :class="['sort', this.statusSort == '' ? '' : this.statusSort == 'ASC' ? 'ASC' : 'DESC']" @click.prevent="onStatusClick">{{ translate('status') }}</a></td>
                            <td><a href="#" :class="['sort', this.vertragsartSort == '' ? '' : this.vertragsartSort == 'ASC' ? 'ASC' : 'DESC']" @click.prevent="onVertragsartClick">{{ translate('contract_type') }}</a></td>
                            <td><a href="#" :class="['sort', this.investitionSort == '' ? '' : this.investitionSort == 'ASC' ? 'ASC' : 'DESC']" @click.prevent="onInvestitionClick">{{ translate('investments') }}</a></td>
                            <td><a href="#" :class="['sort', this.geplanteRuckzahlungSort == '' ? '' : this.geplanteRuckzahlungSort == 'ASC' ? 'ASC' : 'DESC']" @click.prevent="onGeplanteRuckzahlungClick">{{ translate('contract_end') }}</a></td>
                            <td><a href="#" :class="['sort', this.progErtragSort == '' ? '' : this.progErtragSort == 'ASC' ? 'ASC' : 'DESC']" @click.prevent="onProgErtragClick">{{ translate('paid_back_so_far') }}</a></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody id="investmentTable">
                        <template v-for="investment in investments">
                            <tr :key="investment.id">
                                <td v-if="investment.nice_url"><a :href='investment.nice_url'>{{ investment.projekte }}</a></td>
                                <td v-else>{{ investment.projekte }}</td>
                                <td>{{ getInvestmentStatusLabel(investment.status) }}</td>
                                <td>{{ translate(investment.vertragsart) }}</td>
                                <td><span class="ntl-monetary">{{ investment.investition }}€</span></td>
                                <td v-if="investment.status == 'verspätet' || investment.status == 'in Betreibung'">
                                    <a :href="getDelayContactUrl(investment)" target="_blank" role="button" class="btn btn-secondary small" v-if="getDelayContactUrl(investment)">{{ translate('contact') }}</a>
                                </td>
                                <td v-else>{{ investment.geplanteRuckzahlung }}</td>
                                <td v-if="investment.status == 'in Betreibung'"></td>
                                <td v-else><span class="ntl-monetary">{{ investment.zinsenJS | money }}€</span></td>
                                <td>
                                    <a v-if="investment.showButtons" href="#" role="button" class="btn btn-lightest small" data-toggle="collapse" :data-target="'#'+investment.id" aria-expanded="false" @click="getDetails(investment.id)">{{ translate('details') }}</a>
                                </td>
                                <td>
                                    <a v-if="investment.showButtons && investment.showNewsBtn" :href="investment.newsLink" role="button" class="btn small" :class="newsButtonClass(investment)" target="_blank">{{ translate('news') }} <strong v-if="hasUnreadArticles(investment)">({{ numberOfUnreadArticles(investment) }})</strong></a>
                                </td>
                            </tr>
                            <tr v-if="investment.is_cancelled === null || investment.is_cancelled == ''"  :key="investment.id+'details'">
                                <td colspan="9" class="details">
                                    <div :id="investment.id" class="detail collapse" data-parent="#investmentTable">
                                        <div class="title text-center"  v-if="!(investment.id in investmentsDetails)">{{ translate('loading_please_wait') }}</div>
                                        <div class="row" v-else>
                                            <div class="col-12 col-md-8">
                                                <h3>{{ translate('details') }}</h3>
                                                <div class="row mb-5">
                                                    <div class="col-3">
                                                        <b>{{ translate('investment_date') }}</b><br>
                                                        {{ investmentsDetails[investment.id].investitionsdatum }}
                                                    </div>
                                                    <div class="col-3 text-center">
                                                        <b>{{ translate('paid') }}</b><br>
                                                        {{ investmentsDetails[investment.id].bezahlt }}
                                                    </div>
                                                    <div class="col-3 text-center">
                                                        <b>{{ translate('interest_rate_basic') }}</b><br>
                                                        {{ investmentsDetails[investment.id].zinssatz }}%
                                                    </div>
                                                    <div class="col-3 text-right">
                                                        <b>{{ investmentsDetails[investment.id].laufzeitLabel === 'vorauss Laufzeit' ? translate('expected_duration') : translate('duration') }}</b><br>
                                                        {{ getDurationValue(investmentsDetails[investment.id].laufzeit) }}
                                                    </div>
                                                </div>
                                                <h3>{{ translate('returns') }}</h3>
                                                <div class="row">
                                                    <div class="col-3">
                                                        <b>{{ translate('total') }}</b><br>
                                                        <span class="ntl-monetary">{{ investmentsDetails[investment.id].allIncome | money  }}€</span>
                                                    </div>
                                                    <div class="col-3 text-center" v-if="investmentsDetails[investment.id].claim === 0">
                                                        <b>{{ translate('return') }}</b><br>
                                                        <span>
                                                            {{ investmentsDetails[investment.id].renditeJS | money }}%
                                                        </span>
                                                    </div>
                                                    <div class="col-3 text-center" v-else>
                                                        <b class="has-info-box">
                                                            {{ translate('your_claim') }}
                                                            <div class="dg-info-box">
                                                                <div class="dg-info-box-button">
                                                                    <button type="button">
                                                                        <span class="sr-only">{{ translate('show_more_information') }}</span>
                                                                    </button>
                                                                </div>
                                                                <div class="dg-info-box-content">
                                                                    <div class="dg-info-box-close">
                                                                        <button type="button">
                                                                            <span class="sr-only">{{ translate('close') }}</span>
                                                                        </button>
                                                                    </div>
                                                                    <div class="dg-info-box-title">
                                                                        {{ translate('your_claim') }}:
                                                                    </div>
                                                                    <div class="dg-info-box-text">
                                                                        {{ translate('your_claim_info_box_text') }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </b><br>
                                                        <span class="ntl-monetary">
                                                            {{ investmentsDetails[investment.id].claim | money }}€
                                                        </span>
                                                    </div>
                                                    <div class="col-3 text-center">
                                                        <b>{{ translate('interest_received') }}</b><br>
                                                        <span class="ntl-monetary">{{ investmentsDetails[investment.id].ertragErhalten | money }}€</span>
                                                    </div>
                                                    <div class="col-3 text-right">
                                                        <b>{{ translate('capital_received') }}</b><br>
                                                        <span class="ntl-monetary">{{ investmentsDetails[investment.id].kapitalErhalten | money }}€</span>
                                                    </div>
                                                </div>
                                                <h3 class="mt-5" v-if="investment.status != 'unbezahlt'">{{ translate('transactions') }}</h3>
                                                <div class="row" v-if="investment.status != 'unbezahlt'">
                                                    <div class="col-12">
                                                        <table>
                                                            <tr>
                                                                <td><b>{{ translate('date') }}</b></td>
                                                                <td><b>{{ translate('type') }}</b></td>
                                                                <td><b>{{ translate('amount') }}</b></td>
                                                            </tr>
                                                            <tr>
                                                                <td>{{ investmentsDetails[investment.id].bezahlt }}</td>
                                                                <td>{{ translate('payment') }}</td>
                                                                <td>{{ investment.total | money }}</td>
                                                            </tr>
                                                            <tr v-if="investmentsDetails[investment.id].voucher">
                                                                <td>{{ investmentsDetails[investment.id].voucher['date'] }}</td>
                                                                <td>Code: {{ investmentsDetails[investment.id].voucher['code'] }}</td>
                                                                <td>{{ investmentsDetails[investment.id].voucher['value'] | money }}</td>
                                                            </tr>
                                                            <tr v-for="transaction in investmentsDetails[investment.id].transactions" :key="transaction.date">
                                                                <template v-if="transaction.interest < 0 && transaction.capital == 0"></template>
                                                                <template v-else>
                                                                    <td>{{ transaction.date }}</td>
                                                                    <td v-if="transaction.interest > 0 && transaction.capital == 0">{{ translate('interest') }}</td>
                                                                    <td v-else-if="transaction.interest > 0 && transaction.capital > 0 && transaction.basis > 0 ">{{ translate('partial_payback_plus_interest') }}</td>
                                                                    <td v-else-if="transaction.interest > 0 && transaction.capital > 0 && transaction.basis == 0 ">{{ translate('payback_plus_interest') }}</td>
                                                                    <td v-else-if="transaction.interest == 0 && transaction.capital > 0 && transaction.basis > 0 ">{{ translate('partial_payback') }}</td>
                                                                    <td v-else-if="transaction.interest == 0 && transaction.capital > 0 && transaction.basis == 0 ">{{ translate('payback') }}</td>
                                                                    <td v-else-if="transaction.interest < 0 && transaction.capital > 0">{{ translate('partial_payback') }}</td>
                                                                    <td v-if="transaction.interest < 0 && transaction.capital > 0">{{ transaction.capital | money }}</td>
                                                                    <td v-else>{{ (transaction.interest +  transaction.capital) | money }}</td>
                                                                </template>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-4 offset-lg-1 col-lg-3">
                                                <h3>{{ translate('documents') }}</h3>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <a :href="'/pdf/zeichnungsschein/'+investment.id" class="document" target="_blank">{{ translate('subscription_form') }}</a>
                                                        <a v-if="investment.showBondDoc === true" :href="'/pdf/anleihe/'+investment.id" class="document" target="_blank">{{ translate('bond') }}</a>
                                                        <!--<a v-if="investmentsDetails[investment.id].payment_type == 'ELV'" :href="'/pdf/sepa/'+investment.id" class="document" target="_blank">SEPA</a>-->
                                                        <div v-for="document in investmentsDetails[investment.id].documents" :key="document.id">
                                                            <a :href="'/showFile/'+document.file" class="document" target="_blank">{{document.title}}</a>
                                                        </div>
                                                        <div v-for="document in investmentsDetails[investment.id].special_documents" :key="document.id">
                                                            <a v-if="document.showDocument" :href="'/showFile/'+document.file" class="document" target="_blank">{{ translate('special_interest_rate') }}</a>
                                                        </div>
                                                        <div v-for="document in investmentsDetails[investment.id].taxDocuments" :key="document.id">
                                                            <a :href="document.path" class="document" target="_blank">{{ document.title }}</a>
                                                        </div>
                                                        <div v-for="document in investmentsDetails[investment.id].bankruptcyDocuments" :key="document.id">
                                                            <a :href="document.path" class="document" target="_blank">{{ document.title }}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
                <div id="investmentTableM">
                    <table class="d-sm-none mobileTable" v-for="investment in investments" :key="investment.id+'mobile'">
                        <tr>
                            <td colspan="3"><h5>{{ investment.projekte }}</h5></td>
                        </tr>
                        <tr>
                            <td>
                                <b>{{ translate('status') }}</b><br>
                                {{ getInvestmentStatusLabel(investment.status) }}
                            </td>
                            <td class="text-right">
                                <b>{{ translate('contract_end') }}</b><br>
                                <span v-if="investment.status == 'verspätet' || investment.status == 'in Betreibung'">
                                    <a :href="getDelayContactUrl(investment)" target="_blank" role="button" class="btn btn-secondary small" v-if="getDelayContactUrl(investment)">{{ translate('contact') }}</a>
                                </span>
                                <span v-else>{{ investment.geplanteRuckzahlung }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="subTable" colspan="3">
                                <table class="mt-0">
                                    <td>
                                        <b>{{ translate('contract_type') }}</b><br>
                                        {{ translate(investment.vertragsart) }}
                                    </td>
                                    <td class="text-right">
                                        <b>{{ translate('investments') }}</b><br>
                                        <span class="ntl-monetary">{{ investment.investition }}€</span>
                                    </td>
                                </table>
                            </td>
                        </tr>
                        <tr v-if="investment.showMobileDetails">
                            <td class="subTable" colspan="3">
                                <table class="mt-0">
                                    <td>
                                        <a v-if="investment.showButtons" href="#" role="button" class="btn btn-lightest small" data-toggle="collapse" :data-target="'#'+investment.id+'m'" aria-expanded="false" @click="getDetails(investment.id)">{{ translate('details') }}</a>
                                    </td>
                                    <td class="text-right">
                                        <a v-if="investment.showButtons && investment.showNewsBtn" :href="investment.newsLink" role="button" class="btn small" :class="newsButtonClass(investment)" target="_blank">{{ translate('news') }} <strong v-if="hasUnreadArticles(investment)">({{ numberOfUnreadArticles(investment) }})</strong></a>
                                    </td>
                                </table>
                            </td>
                        </tr>
                        <tr v-if="investment.is_cancelled === null || investment.is_cancelled == ''">
                            <td class="subTable" colspan="3">
                                <div :id="investment.id+'m'" class="detail collapse" data-parent="#investmentTableM">
                                    <div class="title text-center"  v-if="!(investment.id in investmentsDetails)">{{ translate('loading_please_wait') }}</div>
                                    <div v-else>
                                        <h3>{{ translate('details') }}</h3>
                                        <div class="row mb-3">
                                            <div class="col-6">
                                                <b>{{ translate('investment_date') }}</b><br>
                                                {{ investmentsDetails[investment.id].investitionsdatum }}
                                            </div>
                                            <div class="col-6 text-right">
                                                <b>{{ translate('paid') }}</b><br>
                                                {{ investmentsDetails[investment.id].bezahlt }}
                                            </div>
                                            <div class="col-6">
                                                <b>{{ translate('interest_rate_basic') }}</b><br>
                                                {{ investmentsDetails[investment.id].zinssatz }}%
                                            </div>
                                            <div class="col-6 text-right">
                                                <b>{{ investmentsDetails[investment.id].laufzeitLabel === 'vorauss Laufzeit' ? translate('expected_duration') : translate('duration') }}</b><br>
                                                {{ getDurationValue(investmentsDetails[investment.id].laufzeit) }}
                                            </div>
                                        </div>
                                        <h3>{{ translate('returns') }}</h3>
                                        <div class="row mb-3">
                                            <div class="col-6">
                                                <b>{{ translate('total') }}</b><br>
                                                <span class="ntl-monetary">{{ investmentsDetails[investment.id].allIncome | money }}€</span>
                                            </div>
                                            <div class="col-6 text-right" v-if="investmentsDetails[investment.id].claim === 0">
                                                <b>{{ translate('return') }}</b><br>
                                                <span>
                                                    {{ investmentsDetails[investment.id].renditeJS | money }}%
                                                </span>
                                            </div>
                                            <div class="col-6 text-right" v-else>
                                                <b class="has-info-box">
                                                    {{ translate('your_claim') }}
                                                    <div class="dg-info-box">
                                                        <div class="dg-info-box-button">
                                                            <button type="button">
                                                                <span class="sr-only">{{ translate('show_more_information') }}</span>
                                                            </button>
                                                        </div>
                                                        <div class="dg-info-box-content">
                                                            <div class="dg-info-box-close">
                                                                <button type="button">
                                                                    <span class="sr-only">{{ translate('close') }}</span>
                                                                </button>
                                                            </div>
                                                            <div class="dg-info-box-title">
                                                                {{ translate('your_claim') }}:
                                                            </div>
                                                            <div class="dg-info-box-text">
                                                                {{ translate('your_claim_info_box_text') }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </b><br>
                                                <span class="ntl-monetary">
                                                    {{ investmentsDetails[investment.id].claim | money }}€
                                                </span>
                                            </div>
                                            <div class="col-6">
                                                <b>{{ translate('interest_received') }}</b><br>
                                                <span class="ntl-monetary">{{ investmentsDetails[investment.id].ertragErhalten | money }}€</span>
                                            </div>
                                            <div class="col-6 text-right">
                                                <b>{{ translate('capital_received') }}</b><br>
                                                <span class="ntl-monetary">{{ investmentsDetails[investment.id].kapitalErhalten | money }}€</span>
                                            </div>
                                        </div>
                                        <h3 v-if="investment.status != 'unbezahlt'">{{ translate('transactions') }}</h3>
                                        <div class="row" v-if="investment.status != 'unbezahlt'">
                                            <div class="col-12">
                                                <table class="mt-0 mb-4">
                                                    <tr>
                                                        <td><b>{{ translate('date') }}</b></td>
                                                        <td><b>{{ translate('type') }}</b></td>
                                                        <td><b>{{ translate('amount') }}</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td>{{ investmentsDetails[investment.id].bezahlt }}</td>
                                                        <td>{{ translate('payment') }}</td>
                                                        <td>{{ investment.total | money }}</td>
                                                    </tr>
                                                    <tr v-if="investmentsDetails[investment.id].voucher">
                                                        <td>{{ investmentsDetails[investment.id].voucher['date'] }}</td>
                                                        <td>Code: {{ investmentsDetails[investment.id].voucher['code'] }}</td>
                                                        <td>{{ investmentsDetails[investment.id].voucher['value'] | money }}</td>
                                                    </tr>
                                                    <tr v-for="transaction in investmentsDetails[investment.id].transactions" :key="transaction.date">
                                                        <td>{{ transaction.date }}</td>
                                                        <td v-if="transaction.interest > 0 && transaction.capital == 0">{{ translate('interest') }}</td>
                                                        <td v-else-if="transaction.interest > 0 && transaction.capital > 0 && transaction.basis > 0 ">{{ translate('partial_payback_plus_interest') }}</td>
                                                        <td v-else-if="transaction.interest > 0 && transaction.capital > 0 && transaction.basis == 0 ">{{ translate('payback_plus_interest') }}</td>
                                                        <td v-else-if="transaction.interest == 0 && transaction.capital > 0 && transaction.basis > 0 ">{{ translate('partial_payback') }}</td>
                                                        <td v-else-if="transaction.interest == 0 && transaction.capital > 0 && transaction.basis == 0 ">{{ translate('payback') }}</td>
                                                        <td>{{ (transaction.interest +  transaction.capital) | money }}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <h3>{{ translate('documents') }}</h3>
                                        <div class="row">
                                            <div class="col-12">
                                                <a :href="'/pdf/zeichnungsschein/'+investment.id" class="document" target="_blank">{{ translate('subscription_form') }}</a>
                                                <a v-if="investment.showBondDoc === true" :href="'/pdf/anleihe/'+investment.id" class="document" target="_blank">{{ translate('bond') }}</a>
                                                <!--<a v-if="investmentsDetails[investment.id].payment_type == 'ELV'" :href="'/pdf/sepa/'+investment.id" class="document" target="_blank">SEPA</a>-->
                                                <div v-for="document in investmentsDetails[investment.id].documents" :key="document.id">
                                                    <a :href="'/showFile/'+document.file" class="document" target="_blank">{{document.title}}</a>
                                                </div>
                                                <div v-for="document in investmentsDetails[investment.id].special_documents" :key="document.id">
                                                    <a v-if="document.showDocument" :href="'/showFile/'+document.file" class="document" target="_blank">{{ translate('special_interest_rate') }}</a>
                                                </div>
                                                <div v-for="document in investmentsDetails[investment.id].taxDocuments" :key="document.id">
                                                    <a :href="document.path" class="document" target="_blank">{{ document.title }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Xhr from '../core/xhr';
    import Vue from 'vue'

    export default {
        props: {
            projectsWithUnreadArticles: Object,
            isOnDashboard: {
                type: Boolean,
                default: false
            },
            disableCsvDownload: {
                type: Boolean,
                default: false
            }
        },

        created() {
            if (this.isOnDashboard) {
                this.getSortedItems();
                return;
            }

            App.dispatcher.listen('filterChange', (response) => {
                this.filters = response.filters;
                if(this.filters.length > 0) {
                    this.getSortedItems();
                } else {
                    this.investments = [];
                }
            })
        },

        methods: {
            removeSorting(column) {
                this.sortingBy = '';
                this.sortingDirection = '';

                if(column != 'project')
                    this.projectSort = '';
                if(column != 'status')
                    this.statusSort = '';
                if(column != 'vertragsart')
                    this.vertragsartSort = '';
                if(column != 'progertrag')
                    this.progErtragSort = '';
                if(column != 'geplanteRuckzahlung')
                    this.geplanteRuckzahlungSort = '';
                if(column != 'investition')
                    this.investitionSort = '';
            },

            onProjectClick() {
                this.removeSorting('project');
                this.sortingBy = 'project';

                if(this.projectSort == '' || this.projectSort == 'DESC'){
                    this.projectSort = 'ASC';
                    this.sortingDirection = 'ASC';
                } else {
                    this.projectSort = 'DESC';
                    this.sortingDirection = 'DESC';
                }

                this.getSortedItems();
            },

            onStatusClick() {
                this.removeSorting('status');
                this.sortingBy = 'status';

                if(this.statusSort == '' || this.statusSort == 'DESC'){
                    this.statusSort = 'ASC';
                    this.sortingDirection = 'ASC';
                } else {
                    this.statusSort = 'DESC';
                    this.sortingDirection = 'DESC';
                }

                this.getSortedItems();
            },

            onVertragsartClick() {
                this.removeSorting('vertragsart');
                this.sortingBy = 'contract';

                if(this.vertragsartSort == '' || this.vertragsartSort == 'DESC'){
                    this.vertragsartSort = 'ASC';
                    this.sortingDirection = 'ASC';
                } else {
                    this.vertragsartSort = 'DESC';
                    this.sortingDirection = 'DESC';
                }

                this.getSortedItems();
            },

            onProgErtragClick() {
                this.removeSorting('progertrag');
                this.sortingBy = 'income';

                if(this.progErtragSort == '' || this.progErtragSort == 'DESC'){
                    this.progErtragSort = 'ASC';
                    this.sortingDirection = 'ASC';
                } else {
                    this.progErtragSort = 'DESC';
                    this.sortingDirection = 'DESC';
                }

                this.getSortedItems();
            },

            onGeplanteRuckzahlungClick() {
                this.removeSorting('geplanteRuckzahlung');
                this.sortingBy = 'repayment';

                if(this.geplanteRuckzahlungSort == '' || this.geplanteRuckzahlungSort == 'DESC'){
                    this.geplanteRuckzahlungSort = 'ASC';
                    this.sortingDirection = 'ASC';
                } else {
                    this.geplanteRuckzahlungSort = 'DESC';
                    this.sortingDirection = 'DESC';
                }

                this.getSortedItems();
            },

            onInvestitionClick() {
                this.removeSorting('investition');
                this.sortingBy = 'investment';

                if(this.investitionSort == '' || this.investitionSort == 'DESC'){
                    this.investitionSort = 'ASC';
                    this.sortingDirection = 'ASC';
                } else {
                    this.investitionSort = 'DESC';
                    this.sortingDirection = 'DESC';
                }

                this.getSortedItems();
            },

            getSortedItems() {
                this.loading = 1;
                this.investments = [];
                new Xhr({sort: this.sortingBy, direction: this.sortingDirection, filters: this.filters}).post('/backend/dashboard/sortInvestments').then((response) => {
                    this.investments = response;
                    this.loading = 0;
                })
            },

            getDetails(invoiceId) {
                if(!(invoiceId in this.investmentsDetails)) {
                    new Xhr({invoiceId: invoiceId}).post('/backend/dashboard/getInvestmentData').then((response) => {
                        Vue.set(this.investmentsDetails, invoiceId, response);

                        this.$nextTick(() => {
                            // This needs to be called so newly added info
                            // boxes are initialized
                            window.initializeInfoBoxes();
                        });
                    })
                }
            },

            downloadCSV() {
                const params = [];

                if (this.sortingBy && this.sortingDirection) {
                    params.push('sort=' + this.sortingBy);
                    params.push('direction=' + this.sortingDirection);
                }

                if (this.filters) {
                    params.push('filters=' + this.filters);
                }

                window.location.href = '/backend/dashboard/downloadCSV?' + params.join('&');
            },

            getInvestmentStatusLabel (status) {
                switch (status) {
                    case '':
                        return '';
                    case 'im Verdienen':
                        return this.translate('active');
                    default:
                        return this.translate(status);
                }
            },

            getDurationValue (duration) {
                if (duration === 'noch nicht zugezählt') {
                    return this.translate('not_yet_accounted_for');
                }

                return `${duration.split(' ')[0]} ${this.translate('months')}`;
            },

            getDelayContactUrl (investment) {
                return investment.delayMailLink;
            },

            hasUnreadArticles (investment) {
                const projectId = investment.id.split('-')[0]

                return projectId in this.projectsWithUnreadArticles
            },

            numberOfUnreadArticles (investment) {
                const projectId = investment.id.split('-')[0]

                if (!(projectId in this.projectsWithUnreadArticles)) {
                    return 0
                }

                return this.projectsWithUnreadArticles[projectId]
            },

            newsButtonClass (investment) {
                const hasUnreadArticles = this.hasUnreadArticles(investment)

                return {
                    'btn-secondary': hasUnreadArticles,
                    'btn-lightest': !hasUnreadArticles
                }
            }
        },

        filters: {
            money(value) {
                return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((value));
            }
        },
        data() {
            return {
                investments: [],
                projectSort: 'ASC',
                statusSort: '',
                vertragsartSort: '',
                progErtragSort: '',
                geplanteRuckzahlungSort: '',
                investitionSort: '',
                sortingBy: '',
                sortDirection: '',
                filters: '',
                loading: 0,
                investmentsDetails: {}
            };
        }
    }
</script>
